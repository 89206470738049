
import { useIsAuthenticated } from '@/composition-api/useIsAuthenticated';
import router from '@/router';
import { mdiChevronUp } from '@mdi/js';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import Header from '@/components/Header.vue';
import ModalButton from '@/components/util/ModalButton.vue';
import { featureset } from '@/plugins/smd-feature';

export default defineComponent({
  components: {
    Header,
    ModalButton,
  },
  setup() {
    const topVisible = ref(false);
    const store = useStore();

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop >= 300) {
        topVisible.value = true;
      } else {
        topVisible.value = false;
      }
    });

    if (featureset.login == 'ad') {
      const auth = useIsAuthenticated();

      const datainstore = computed(() => store.getters.isLoggedIn);
      store.dispatch('loadStore').then(() => {
        if (!auth.value || !datainstore.value) {
          router.push({ name: 'Home' });
        }
      });
    }

    return {
      scrollToTop,
      mdiChevronUp,
      topVisible,
    };
  },
});
