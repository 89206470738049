
import { defineComponent } from 'vue';
import { mdiHome, mdiWrench, mdiAccountGroup, mdiCogOutline, mdiLogout } from '@mdi/js';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Tooltip from './util/Tooltip.vue';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const onLogout = () => {
      store.dispatch('logout').then(() => {
        router.push({ name: 'Home' });
      });
    };
    const onSettings = () => {
      router.push({ name: 'MitarbeiterList' });
    };

    const onMitarbeiter = () => {
      router.push({ name: 'MitarbeiterIndex' });
    };

    return {
      mdiCogOutline,
      mdiLogout,
      mdiAccountGroup,
      mdiHome,
      mdiWrench,
      onLogout,
      onSettings,
      onMitarbeiter,
    };
  },
  components: { Tooltip },
});
